import * as React from 'react'
import PageLayout from '../components/layout/PageLayout'
import { graphql } from 'gatsby'
import Journal from './Journal'

const JournalPage = ({data}) => {

  return (
    <PageLayout pageTitle={ data.tourJournal.title}
            image={data.tourJournal.featureImage}
            editUrl={ data.tourJournal.editUrl } >
    <Journal
      locationRecordings={ data.tourJournal.locationRecordings }
      photos={ data.tourJournal.photos }
      media= { data.tourJournal.media }
      displayDateLine={ data.tourJournal.displayDateLine }
      body={ data.tourJournal.parent.body }

      pagePrevious={ data.tourJournal.pagePrevious }
      pageParent={ data.tourJournal.pageParent }
      pageNext={ data.tourJournal.pageNext }
    />
    </PageLayout>
  )
}

export const query = graphql`
query ($id: String) {
  tourJournal(id: {eq:$id}) {
    ...PageMetadata
    ...PageFull
    ...RelatedPages
    locationRecordings {
      ...LocationRecordingDetailed
    }
    media {
      ...MediaData
    }
  }
}
`

export default JournalPage
