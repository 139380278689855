import * as React from 'react'
import PropTypes from 'prop-types'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { resizeImageData } from '../../util/images'

import EditIcon from '../admin/EditIcon';
import { formatEditLink } from '../../util/cms'

import { VideoPlayer, PREVIEW_OPTIONS } from '../video/VideoPlayer'

import {
  gallery,
  favorite,
  liked,
  edit,
  play
} from './gallery.module.scss'

export default class GalleryCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = { isFocused: false }
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.focusMediaItem = this.focusMediaItem.bind(this)
  }

  setFocus(isActive){
      this.setState(prev => ({
          isActive: isActive
      }))
  }

  onMouseEnter() {
    this.setFocus(true);
  }

  onMouseLeave() {
    this.setFocus(false);
  }

  focusMediaItem() {
    this.props.focusMedia(this.props.mediaItem)
  }

  resizeGalleryImageData(imageData) {
    const desktopMinPixels = 1024
    const tabletMinPixels = 769
    const sizes = `(min-width: ${desktopMinPixels}px) 25vw, (min-width: ${tabletMinPixels}px) 50vw, 100vw`
    return resizeImageData(imageData, sizes);
  }

  renderContent(node) {
    if(node.gatsbyImageData) {
      const imageData = getImage(node);
      const fixedImageData = this.resizeGalleryImageData(imageData);

      return (<GatsbyImage
                className="card-image"
                image={fixedImageData}
                alt={node.description ? node.description : ""}
              />)
    }
    else if(node.videoData) {
      const imageNode = node.videoData.preview.image;
      const imageData = getImage(imageNode)
      const fixedImageData = this.resizeGalleryImageData(imageData);

      if(this.state.isActive) {
        return (<VideoPlayer className="card-image" sources= { node.videoData.preview.sources} options={ PREVIEW_OPTIONS } />)
      }
      else {
        return (
          <GatsbyImage
            className="card-image"
            image={fixedImageData}
            alt={node.description ? node.description : ""}
          />)
      }
    }
    return null;
  }

  displayScope(node) {
    if(node.scope === "GLOBAL") {
      return (
        <div className={ `icon ${favorite}` } title="favorite">
          <i className="fas fa-star"></i>
        </div>)
    }
    else if(node.scope === "COLLECTION") {
      return (
        <div className={ `icon ${liked}` } title="liked">
          <i className="far fa-star"></i>
        </div>)
    }
  }

  displayPlay(node) {
    if(node.videoData && ! this.state.isActive){
      return (
        <div className={ `icon ${play}` } title="play">
          <i className="fa fa-play-circle"></i>
        </div>)
    }
  }

  render() {
    const { mediaItem, galleryMinPriority } = this.props;
    const entityName = mediaItem.gatsbyImageData ? "images" : "videos"

    return (
        <div className="card"
             onClick={ this.focusMediaItem }
             onMouseEnter={this.onMouseEnter}
             onMouseLeave={this.onMouseLeave} >

            <figure className="card-image" title={mediaItem.description}>
                <div className={ mediaItem.gatsbyImageData ? "card-image-wrapper" : null } >
                    { this.renderContent(mediaItem) }
                </div>
                <figcaption className='has-text-grey-lighter'>
                  { mediaItem.description && mediaItem.description.trim().length > 0 ? mediaItem.description : null }
                </figcaption>
            </figure>

            {/* TODO prevent click of edit icon from opening gallery  */}
            <EditIcon className={ edit } editUrl={ formatEditLink(entityName, mediaItem.title) } />
            {  mediaItem.priority !== galleryMinPriority ? this.displayScope(mediaItem) : null }
            { this.displayPlay(mediaItem) }
        </div>
    )
  }

}

GalleryCard.propTypes = {
  focusMedia: PropTypes.func.isRequired,
  mediaItem: PropTypes.object.isRequired
};
