import * as React from 'react'
import PropTypes from 'prop-types'

import GallerySection from '../components/gallery/GallerySection'
import Map from '../components/map/Map'
import ContentSection from '../components/markdown/ContentSection'
import ArticlesCard from '../components/articles/ArticlesCard'
import LayoutSection from '../components/layout/LayoutSection'
import { MediaModalControls } from '../components/modal/MediaModalControls'

export default class JournalTemplate extends React.Component {

  render() {
    const { locationRecordings=[],
            media=[],
            displayDateLine, body,
            pagePrevious, pageParent, pageNext } = this.props
    const articles = [pagePrevious, pageParent, pageNext];

    return (
        <MediaModalControls media={media}>
          <LayoutSection id='map-section'>
            <Map recordings={locationRecordings} photos={ media } />
          </LayoutSection>

          <ContentSection title={ displayDateLine } body={body} />
          <GallerySection media={ media } />

          <LayoutSection id='related-posts' label="Related Posts">
            <ArticlesCard articles={ articles } />
          </LayoutSection>
        </MediaModalControls>
    )
  }

}

JournalTemplate.propTypes = {
  locationRecordings: PropTypes.arrayOf(PropTypes.object.isRequired),
  photos: PropTypes.arrayOf(PropTypes.object.isRequired),
  displayDateLine: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
}
