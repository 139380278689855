import * as React from 'react'
import PropTypes from 'prop-types'

import Gallery from './Gallery'
import LayoutSection from '../layout/LayoutSection'
import { MediaContext } from '../modal/MediaModalControls'

class GallerySection extends React.Component {

  render() {
    const { media, focusMedia } = this.props;
    if(media && media.length) {
      return (
        <LayoutSection id="Gallery-Section">
          <MediaContext.Consumer>
          {(context) =>
            <Gallery media={ media } focusMedia={ context.focusMedia } />
          }
          </MediaContext.Consumer>
        </LayoutSection>
      )
    }
    else {
      return null;
    }
  }

}

GallerySection.propTypes = {
  media: PropTypes.arrayOf(PropTypes.object.isRequired) // TODO narrow object
};

export default GallerySection;
