import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import EditIcon from '../admin/EditIcon';
import VideoPlayer from '../video/VideoPlayer'

import { resizeImageData } from '../../util/images'
import { formatEditLink } from '../../util/cms'

import GalleryCard from './GalleryCard';

import {
  gallery,
  favorite,
  liked,
  edit
} from './gallery.module.scss'

export default class Gallery extends React.Component {

  render() {
    const minPriority=this.props.media.map(x => x.priority).filter(x=> typeof x ==='number' ).reduce((a,b) => { return Math.min(a,b) }, 4)

    return (
        <div className={`section columns is-multiline ${gallery}`}>
          {
            this.props.media.map(node => {
              return (
                <div key={node.id} className="column is-one-quarter-desktop is-half-tablet is-align-self-stretch">
                    <GalleryCard focusMedia={ this.props.focusMedia } mediaItem={ node } galleryMinPriority={ minPriority } />
                </div>
              )
            })
          }
        </div>
    )
  }
}
